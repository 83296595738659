
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorsServices } from '../interfaces/ErrorsServices';
import { catchError, lastValueFrom, tap } from 'rxjs';
import { generateApiKey, setCript } from '../shared/helpers/encryption';
import { v4 as uuidv4 } from 'uuid';
import { dataToRegister } from '../shared/helpers/dataAggregator';
import { Router } from '@angular/router';
import { ErrorsPage } from '../interfaces/ErrorsPage';
import { EndFactoryService } from '../factories/endfactory.service';
import { LocalStorage, SessionStorage } from '../interfaces/dataStorage';
import { datadogSetErrorType } from '../shared/helpers/datadog';
import { RoutesPages } from '../interfaces/routes';

@Injectable({
	providedIn: 'root',
})
export class CoreService {
	// Essa Classe deverá ser descontinuada quando migrarmos para Bio 2.0
	constructor(
		private http: HttpClient,
		public router: Router,
		public factory: EndFactoryService
	) {
		// @ts-ignore
		window.register = this.register.bind(this);
		// @ts-ignore
		window.preRegister = this.preRegister.bind(this);
	}

	public async sendParams(url: string, data: any, params: any, callback?: (e: any) => void, errorCallback?: (e: any) => void, afterCallback?: () => void) {
		let additionalHeaders: any = {};
		const { key, payload, error } = await setCript(data);
		this.logs(payload, error);
		const useMigratedEnvironment = sessionStorage.getItem(SessionStorage.UseMigratedEnvironment);
		const apiKey = generateApiKey(6);

		if (params?.type_liveness === 'facetec') {
			additionalHeaders = {
				'liveness-engine': 'facetec',
			}
		}

		if (params?.logCode) {
			additionalHeaders = {
				'log-code': params.logCode,
			}
		}

		if (useMigratedEnvironment === 'true') {
			additionalHeaders = {
				'use_migrated_environment': 'true',
			}
		}

		const headers = new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': 'true',
			'Access-Control-Allow-Methods': 'GET, POST, PUT',
			'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
			'x-itau-apikey': apiKey,
			'Content-Type': 'application/json',
			'X-Charon-Session': params?.carontSession,
			'x-itau-flowID': apiKey,
			'x-itau-correlationID': uuidv4(),
			'aes': key,
			'Authorization': 'Bearer ' + params?.token,
			...additionalHeaders,
		});

		try {
			const response = await lastValueFrom(this.http.post(url, payload, { headers: headers })
				.pipe(
					tap((res: any) => {
						if (callback) callback(res);
					}),
					catchError((error: any) => {
						if (errorCallback) errorCallback(error);
						throw error;
					}),
				));

			if (afterCallback) afterCallback();
			return response;
		} catch (error) {
			console.error(ErrorsServices.SendParamsFail, error)
		}
	}

	public async register(payloadFromLib) {
		let additionalHeaders: any = {};
		const useMigratedEnvironment = sessionStorage.getItem(SessionStorage.UseMigratedEnvironment);
		const carontSession = localStorage.getItem(LocalStorage.CarontSessionLink) || " ";
		const token = sessionStorage.getItem(LocalStorage.Token);
		const registerLink = localStorage.getItem(LocalStorage.RegisterLink) || '';
		const { key, payload } = await setCript(payloadFromLib);
		const apiKey = generateApiKey(6);

		if (useMigratedEnvironment === 'true') {
			additionalHeaders = {
				'use_migrated_environment': 'true',
			}
		}

		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': 'true',
			'Access-Control-Allow-Methods': 'GET, POST, PUT',
			'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
			'x-itau-apikey': apiKey,
			'X-Charon-Session': carontSession,
			'x-itau-flowID': apiKey,
			'x-itau-correlationID': uuidv4(),
			'aes': key,
			...additionalHeaders,
		});

		try {
			const response = await lastValueFrom(this.http.post(registerLink, payload, { headers: headers })
				.pipe(
					tap((res: any) => {
						this.factory.resolveFactory(res);
					}),
					catchError((error: any) => {
						this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.ERROR_SERVER } });
						datadogSetErrorType(ErrorsPage.ERROR_SERVER);
						throw error;
					}),
				));
			return response;
		} catch (error) {
			console.error(ErrorsServices.RegisterFail, error)
		}
	}

	public async logs(data, error?) {
		const carontSession = localStorage.getItem(LocalStorage.CarontSessionLink) || " ";
		const token = sessionStorage.getItem(LocalStorage.Token);
		const logsLink = localStorage.getItem(LocalStorage.LogsLink) || '';
		const apiKey = generateApiKey(6);
		const payloadToLogs = {
			nom_titulo: "Called Session API",
			mensagem: error ? error : data,
			chave_captura: localStorage.getItem(LocalStorage.CaptureKey),
		}
		const { key, payload } = await setCript(payloadToLogs);
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': 'true',
			'Access-Control-Allow-Methods': 'GET, POST, PUT',
			'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
			'x-itau-apikey': apiKey,
			'X-Charon-Session': carontSession,
			'x-itau-flowID': apiKey,
			'x-itau-correlationID': uuidv4(),
			'aes': key,
		});

		try {
			const response = await lastValueFrom(this.http.post(logsLink, payload, { headers: headers })
				.pipe(
					tap((res: any) => {
						return res;
					}),
					catchError((error: any) => {
						throw error;
					}),
				));
			return response;
		} catch (error) {
			console.error(ErrorsServices.LogsFail, error)
		}
	}

	public async preRegister(payloadFromLib) {
		const payloadValue = JSON.parse(payloadFromLib)
		let payloadRegisterPlus: any = dataToRegister();
		payloadRegisterPlus.conteudo_analise_sdk = { ...payloadValue };
		this.register(payloadRegisterPlus);
	}

}