import { Component } from '@angular/core';
import { LOADING_RECEIVED_PHOTO, LOADING_VALIDATE, LOADING_SECURITY } from '../../shared/helpers/paramsGa4';
import { MainService } from '../../services/main.service';
import { LOGS_SUCCESS_PAGE } from '../../shared/helpers/stepsToLogs';
import { LocalStorage } from '../../interfaces/dataStorage';
import { callSuccess } from '../../shared/helpers/callback';
import { AnalyticsGA4Service } from '../../services/analyticsGA4.service';
import { NavigationEntryService } from '../../services/navigationEntry.service';

@Component({
  selector: 'success-root',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent {
  loading: boolean = true;
  loadingTwo: boolean = false;
  loadingFinish: boolean = false;

  constructor(
    private analyticsGA4Service: AnalyticsGA4Service,
    public mainService: MainService,
    public navigationEntry: NavigationEntryService,
  ) { }

  ngOnInit() {
    this.navigationEntry.verifyNavigationEntry();
    setTimeout(() => {
      this.loading = false;
      this.loadingTwo = true;
      this.analyticsGA4Service.trackEventGA4(LOADING_VALIDATE);
    }, 2000);
    setTimeout(() => {
      this.loadingTwo = false;
      this.loadingFinish = true;
      this.analyticsGA4Service.trackEventGA4(LOADING_SECURITY);
    }, 4000);
    setTimeout(() => {
      // Fluxo Bio 2.0
      // const result = this.mainService.matchAPI();
      // // Validar quando houver contrato com o backend, qual valor precisamos ler de resultado.
      // if(result) {
      //   callSuccess();
      // } else
      // callError();

      // Usado no endFlow do fluxo antigo, será removido na Bio 2.0
      this.analyticsGA4Service.trackEventGA4(LOADING_RECEIVED_PHOTO);
      callSuccess();
    }, 6000);
    if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
      this.mainService.insightsAPI(LOGS_SUCCESS_PAGE);
    }
  }
}
