import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DEFAULT_PARAMS, DEFAULT_CONFIG } from '../shared/helpers/paramsGa4';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsGA4Service {

  constructor() { }

  private getSDK() {
    let url = environment.urlSdkGA4;
    const script = document.createElement('script');

    return new Promise((resolve, reject) => {
      script.src = url;
      script.async = true;
      script.onload = () => resolve(window['SDKAnalytics']);
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  async initGA4Sdk() {
    try {
      this.getSDK()
        .then((SdkAnalytics: any) => SdkAnalytics(DEFAULT_CONFIG))
        .then((ItauDigitalAnalytics) => {
          ItauDigitalAnalytics.setDefaultParameters(DEFAULT_PARAMS);
        });
    } catch (error) {
      console.log('error -> ', error);
    }
  }

  trackEventGA4(data: any) {
    try {
      this.getSDK()
        .then((SdkAnalytics: any) => SdkAnalytics(DEFAULT_CONFIG))
        .then((ItauDigitalAnalytics) => {
          ItauDigitalAnalytics.setDefaultParameters(DEFAULT_PARAMS);
          ItauDigitalAnalytics.track(data);
        });
    } catch (error) {
      console.log('error -> ', error);
    }
  }
}