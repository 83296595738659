import { LocalStorage, SessionStorage } from "../../interfaces/dataStorage";

export const dataStorageClean = () => {
  localStorage.removeItem(LocalStorage.CaptureKey);
  localStorage.removeItem(LocalStorage.CarontSessionLink);
  localStorage.removeItem(LocalStorage.Supplier);
  localStorage.removeItem(LocalStorage.Token);
  localStorage.removeItem(LocalStorage.LogsLink);
  localStorage.removeItem(LocalStorage.RegisterLink);
  localStorage.removeItem(LocalStorage.CharonInsights);
  localStorage.removeItem(LocalStorage.CharonFaceAnalyzer);
  localStorage.removeItem(LocalStorage.CharonEntrypoint);
  localStorage.removeItem(LocalStorage.ToggleLogs);
  localStorage.removeItem(LocalStorage.NumberTry);

  sessionStorage.removeItem(SessionStorage.Page);
  sessionStorage.removeItem(SessionStorage.CpfIdpessoa);
  sessionStorage.removeItem(SessionStorage.OriginProcess);
  sessionStorage.removeItem(SessionStorage.Cn);
  sessionStorage.removeItem(SessionStorage.Environment);
  sessionStorage.removeItem(SessionStorage.ChannelType);
  sessionStorage.removeItem(SessionStorage.PagePrefix);
  sessionStorage.removeItem(SessionStorage.RedirectUrl);
  sessionStorage.removeItem(SessionStorage.RedirectSuccess);
  sessionStorage.removeItem(SessionStorage.RedirectError);
  sessionStorage.removeItem(SessionStorage.RedirectUrlError);
  sessionStorage.removeItem(SessionStorage.RedirectUrlRetry);
  sessionStorage.removeItem(SessionStorage.ParamsUrl);
  sessionStorage.removeItem(SessionStorage.UseMigratedEnvironment);
}