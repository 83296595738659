import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitFactoryService } from '../../factories/initfactory.service';
import { TIPS_PAGE, TIPS_PAGE_CLICK, LOADING_FIRST, TIPS_PAGE_CLICK_BACK, TIPS_PAGE_ENTRANCE, TIPS_PAGE_CLICK_HELP } from '../../shared/helpers/paramsGa4';
import { ErrorsPage } from '../../interfaces/ErrorsPage';
import { MainService } from '../../services/main.service';
import { LOGS_TIPS_PAGE } from '../../shared/helpers/stepsToLogs';
import { LocalStorage } from '../../interfaces/dataStorage';
import { datadogSetErrorType } from '../../shared/helpers/datadog';
import { RoutesPages } from '../../interfaces/routes';
import { TypesMessages } from '../../interfaces/ErrorsServices';
import { verifyDeviceInfoOfAgent } from '../../shared/helpers/verification';
import { AnalyticsGA4Service } from '../../services/analyticsGA4.service';
import { BottomSheet } from '../../shared/components/bottom-sheet/bottom-sheet.component';

@Component({
  selector: 'app-main-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  showLoading = false;
  showButtonBack = true;
  showButton = true;
  showShimmer = true;
  isVerifyCamera = false;
  routePage = RoutesPages.Main;
  @ViewChild('elementFocus') elementFocus: ElementRef;
  @ViewChild(BottomSheet) BottomSheet: any;

  constructor(
    public factory: InitFactoryService,
    public router: Router,
    private analyticsGA4Service: AnalyticsGA4Service,
    public mainService: MainService,
    public route: ActivatedRoute,
  ) { }

  async ngAfterViewInit() {
    const isNavigationReturn = this.route.snapshot?.queryParams?.type || false;

    if (isNavigationReturn) {
      this.showShimmer = false;
      this.updateButtonStatus();
      this.updateButtonBackStatus();
      return;
    }

    await this.initializeComponent();
  }

  private async initializeComponent() {
    this.showShimmer = true;
    this.analyticsGA4Service.trackEventGA4(LOADING_FIRST);
    const initialization = await this.factory.initializeFactory();

    if (initialization !== TypesMessages.SUCCESS) {
      this.navigateToErrorPage(ErrorsPage.FAIL_KEY);
    } else {
      this.analyticsGA4Service.trackEventGA4(TIPS_PAGE);
      this.analyticsGA4Service.trackEventGA4(TIPS_PAGE_ENTRANCE);
      this.updateButtonStatus();
      this.updateButtonBackStatus();
      setTimeout(() => this.showShimmer = false, 4000);
      setTimeout(() => this.elementFocus.nativeElement.focus(), 5000);
      if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
        this.mainService.insightsAPI(LOGS_TIPS_PAGE);
      }
    }
  }

  async startCam() {
    this.showLoading = true;
    this.analyticsGA4Service.trackEventGA4(TIPS_PAGE_CLICK);
    const hasPermission = await this.verifyCameraPermission();
    hasPermission ? this.startCamLoading() : this.verifyCamera();
  }

  private startCamLoading() {
    const startCamLoad = setInterval(() => {
      if (!this.factory.showLoading) {
        clearInterval(startCamLoad);
        this.showLoading = false;
        this.factory.startCam();
      }
    }, 1000);
  }

  private navigateToErrorPage(errorCode: string) {
    this.router.navigate([RoutesPages.Error], { queryParams: { errorCode } });
    datadogSetErrorType(errorCode);
  }

  private async verifyCameraPermission() {
    const permissionStatus = await navigator?.permissions?.query({ name: 'camera' as PermissionName });
    if (permissionStatus?.state === 'granted') return true;
    if (permissionStatus?.state === 'prompt' || permissionStatus?.state === 'denied') return false;
    this.incompatibleBrowser();
  }

  private async verifyCamera() {
    if (navigator?.mediaDevices?.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const track = stream.getTracks()[0];
        track ? (track.stop(), this.startCam()) : this.incompatibleBrowser();
      } catch {
        this.cameraError();
      }
    } else {
      this.incompatibleBrowser();
    }
  }

  callHelpPage() {
    this.router.navigate([RoutesPages.Help], { queryParams: { page: RoutesPages.Main } });
    this.analyticsGA4Service.trackEventGA4(TIPS_PAGE_CLICK_HELP);
  }

  private updateButtonStatus() {
    const startButtonLoad = setInterval(() => {
      if (!this.factory.showButton) {
        this.showButton = false;
        clearInterval(startButtonLoad);
      }
    }, 1000);
  }

  private updateButtonBackStatus() {
    this.showButtonBack = history.length > 1;
  }

  private incompatibleBrowser() {
    this.navigateToErrorPage(ErrorsPage.INCOMPATIBLE_BROWSER);
  }

  private cameraError() {
    this.navigateToErrorPage(ErrorsPage.CAMERA_CONFIG);
  }

  verifyIsSafari() {
    const { browser } = verifyDeviceInfoOfAgent();
    return browser === 'Safari';
  }

  callButtomSheet() {
    this.BottomSheet.callBottomSheet();
  }
}