import { datadogRum } from "@datadog/browser-rum";
import { environment } from '../../../environments/environment';
import { LocalStorage, SessionStorage } from '../../interfaces/dataStorage';

export const datadogInit = () => {

  let addParameters: any = {};

  if (!environment.isProd) {
    addParameters = {
      proxy: environment.dataDogProxy,
    }
  }

  datadogRum.init({
    applicationId: environment.dataDogID,
    clientToken: environment.dataDogTK,
    env: environment.production ? 'prod' : 'hom',
    site: 'datadoghq.com',
    service: 'ou3-biometria-captura-web',
    version: environment.appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: environment.dataDogAllowedTracingUrls,
    ...addParameters
  });
}

export const datadogSetUser = () => {
  datadogRum.setUser({
    name: (sessionStorage.getItem(SessionStorage.CpfIdpessoa) || localStorage.getItem(LocalStorage.CaptureKey)) || "captureKey - null",
    captureKey: localStorage.getItem(LocalStorage.CaptureKey),
    supplier: localStorage.getItem(LocalStorage.Supplier),
    numberTry: localStorage.getItem(LocalStorage.NumberTry),
    page: sessionStorage.getItem(SessionStorage.Page),
    CpfIdpessoa: sessionStorage.getItem(SessionStorage.CpfIdpessoa),
    originProcess: sessionStorage.getItem(SessionStorage.OriginProcess),
    channel: sessionStorage.getItem(SessionStorage.Cn),
    environment: sessionStorage.getItem(SessionStorage.Environment),
    channelType: sessionStorage.getItem(SessionStorage.ChannelType),
    pagePrefix: sessionStorage.getItem(SessionStorage.PagePrefix),
    tid: sessionStorage.getItem(SessionStorage.Tid),
    customerSegment: sessionStorage.getItem(SessionStorage.CustomerSegment),
    urlRedirectSuccess: sessionStorage.getItem(SessionStorage.RedirectSuccess),
    urlRedirectError: sessionStorage.getItem(SessionStorage.RedirectError),
    urlRedirectRetry: sessionStorage.getItem(SessionStorage.RedirectRetry),
    toggleLogs: sessionStorage.getItem(LocalStorage.ToggleLogs),
    useMigratedEnvironment: sessionStorage.getItem(SessionStorage.UseMigratedEnvironment),
    errorType: null,
    abandonPage: null,
  });
}

export const datadogSetNumberTry = () => {
  datadogRum.setUserProperty('numberTry', localStorage.getItem(LocalStorage.NumberTry));
}

export const datadogSetSupplier = (suplier: string) => {
  datadogRum.setUserProperty('supplier', suplier);
}

export const datadogSetUrlRedirect = () => {
  datadogRum.setUserProperty('urlRedirectSuccess', sessionStorage.getItem(SessionStorage.RedirectSuccess),);
  datadogRum.setUserProperty('urlRedirectRetry', sessionStorage.getItem(SessionStorage.RedirectRetry),);
  datadogRum.setUserProperty('urlRedirectError', sessionStorage.getItem(SessionStorage.RedirectError),);
}

export const datadogSetToggleLogs = () => {
  datadogRum.setUserProperty('toggleLogs', sessionStorage.getItem(LocalStorage.ToggleLogs),);
}

export const datadogSetErrorType = (error: string) => {
  datadogRum.setUserProperty('errorType', error);
}

export const datadogSetAbandonPage = (page: string) => {
  datadogRum.setUserProperty('abandonPage', page);
}