import { LocalStorage } from "../../interfaces/dataStorage";
import { environment } from '../../../environments/environment';

const getCustomerId = () => localStorage.getItem(LocalStorage.CaptureKey);

const COMMON_PARAMS = {
  task: "se00243-realizar-captura-de-minha-face-dificuldades-no-processo",
  objective: "validar-minha-identidade-com-reconhecimento-facial",
  flow: "capturar-biometria"
};

const createAnalyticsEvent = (eventName: string, customPath: string, detail: string, additionalParams: object = {}) => ({
  analytics: {
    event_name: eventName,
    parameters: {
      custom_path: customPath,
      implementation_team: "capturar-biometria:identificar-e-autenticar",
      detail,
      customer_id: getCustomerId(),
      ...additionalParams
    }
  }
});

const createScreenViewEvent = (customPath: string, detail: string, additionalParams: object = {}) =>
  createAnalyticsEvent("screen_view", customPath, detail, { ...COMMON_PARAMS, ...additionalParams });

export const DEFAULT_CONFIG = {
  gtm: environment.gtmId,
};

export const DEFAULT_PARAMS = {
  analytics: {
    parameters: {
      business: 'pf',
      environment: 'nao-logado',
      business_channel: 'superapp'
    }
  }
};

// Screen View Events
export const LOADING_FIRST = createScreenViewEvent(
  "/biometria/usar/autenticacao/loading-inicial",
  "tela-loading-inicial"
);

export const TIPS_PAGE = createScreenViewEvent(
  "/biometria/usar/autenticacao/confirme-identidade-reconhecimento",
  "tela-confirme-identidade-reconhecimento"
);

export const HELP_PAGE = createScreenViewEvent(
  "/biometria/usar/autenticacao/duvidas-frequentes-reconhecimento",
  "tela-duvidas-frequentes-reconhecimento"
);

export const ERROR_CAM_PERMISSION_PAGE = createScreenViewEvent(
  "/biometria/usar/autenticacao/erro-acesso-camera-reconhecimentoo",
  "autorize-acesso-camera-reconhecimento"
);

export const ERROR_CAM_NOT_RESPOND_PAGE = createScreenViewEvent(
  "/biometria/usar/autenticacao/camera-sem-resposta-reconhecimento",
  "tela-camera-sem-resposta-reconhecimento"
);

export const ERROR_LIMIT_EXCEED = createScreenViewEvent(
  "/biometria/usar/autenticacao/tela-erro-quarentena",
  "tela-erro-quarentena",
  { description: "erro-limite-tentativas-excedido" }
);

export const ERROR_BLOCKED = createScreenViewEvent(
  "/biometria/usar/autenticacao/tela-erro-bloqueio",
  "tela-erro-bloqueio",
  { description: "erro-limite-tentativas-excedido" }
);

export const ERROR_FAIL_KEY = createScreenViewEvent(
  "/biometria/usar/autenticacao/acabou-tempo-tirar-foto",
  "tela-acabou-tempo-tirar-foto"
);

export const ERROR_GENERIC = createScreenViewEvent(
  "/biometria/usar/autenticacao/tela-erro-generico",
  "tela-erro-bloqueio",
  { description: "erro-generico" }
);

export const LOADING_PAGE = createScreenViewEvent(
  "/biometria/loading",
  ""
);

export const AWARE_PAGE = createScreenViewEvent(
  "/biometria/usar/autenticacao/camera-aware",
  "tela-camera-aware"
);

export const TRY_AGAIN_PAGE_AWARE = createScreenViewEvent(
  "/biometria/usar/autenticacao/aware-tente-novamente-reconhecimento",
  "tela-aware-tente-novamente-reconhecimento"
);

export const FACETEC_PAGE = createScreenViewEvent(
  "/biometria/usar/autenticacao/camera-facetec",
  "tela-camera-facetec"
);

export const LOADING_ANALYSE = createScreenViewEvent(
  "/biometria/usar/autenticacao/qualidade-loading-analisando",
  "tela-qualidade-loading-analisando"
);

export const LOADING_MEASURING = createScreenViewEvent(
  "/biometria/usar/autenticacao/qualidade-loading-medindo",
  "tela-qualidade-loading-medindo"
);

export const LOADING_RECEIVED_PHOTO = createScreenViewEvent(
  "/biometria/usar/autenticacao/recebemos-foto",
  "tela-recebemos-foto"
);

export const LOADING_VALIDATE = createScreenViewEvent(
  "/biometria/usar/autenticacao/risco-loading-validacao",
  "tela-risco-loading-validacao"
);

export const LOADING_SECURITY = createScreenViewEvent(
  "/biometria/usar/autenticacao/risco-loading-segura",
  "tela-risco-loading-segura"
);

// Click Events
export const TIPS_PAGE_ENTRANCE = createAnalyticsEvent(
  "entrance",
  "/biometria/usar/autenticacao/confirme-identidade-reconhecimento",
  "tela-confirme-identidade-reconhecimento"
);

export const TIPS_PAGE_CLICK = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/confirme-identidade-reconhecimento",
  "continuar"
);

export const TIPS_PAGE_CLICK_BACK = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/confirme-identidade-reconhecimento",
  "voltar"
);

export const TIPS_PAGE_CLICK_HELP = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/confirme-identidade-reconhecimento",
  "duvidas-frequentes"
);

export const HELP_PAGE_CLICK = (detail: string) => {
  return createAnalyticsEvent(
    "click",
    "/biometria/usar/autenticacao/duvidas-frequentes-reconhecimento",
    detail ? detail : "inicio-da-pergunta-faq"
  );
}

export const HELP_PAGE_CLICK_POLICY = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/duvidas-frequentes-reconhecimento",
  "politica-de-privacidade"
);

export const HELP_PAGE_CLICK_BACK = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/duvidas-frequentes-reconhecimento",
  "voltar"
);

export const ERROR_CAM_PERMISSION_CLICK_BACK = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/erro-acesso-camera-reconhecimento",
  "voltar"
);

export const ERROR_CAM_PERMISSION_CLICK_CONFIG = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/erro-acesso-camera-reconhecimento",
  "ir-para-configuracoes"
);

export const ERROR_CAM_NOT_RESPOND_CLICK_BACK = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/camera-sem-resposta-reconhecimento",
  "voltar"
);

export const ERROR_CAM_NOT_RESPOND_CLICK_OK = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/camera-sem-resposta-reconhecimento",
  "entendi"
);

export const ERROR_LIMIT_EXCEED_CLICK_BACK = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/tela-erro-quarentena",
  "fechar"
);

export const ERROR_BLOCKED_CLICK_CLOSE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/tela-erro-bloqueio",
  "fechar"
);

export const ERROR_FAIL_KEY_CLICK_CLOSE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/acabou-tempo-tirar-foto",
  "fechar"
);

export const ERROR_FAIL_KEY_CLICK_UNDERSTOOD = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/acabou-tempo-tirar-foto",
  "fechar-botao"
);

export const ERROR_GENERIC_CLICK_CLOSE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/tela-erro-generico",
  "fechar"
);

export const ERROR_GENERIC_CLICK_UNDERSTOOD = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/tela-erro-generico",
  "entendi"
);

export const AWARE_CAPTURE_SUCCESS = createAnalyticsEvent(
  "success",
  "/biometria/usar/autenticacao/camera-aware",
  "sucesso-captura-foto"
);

export const AWARE_REGISTER_SUCCESS = createAnalyticsEvent(
  "conversion",
  "/biometria/usar/autenticacao/camera-aware",
  "sucesso-registro-foto"
);

export const AWARE_CLICK_TAKE_PICTURE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/camera-aware",
  "tirar-foto"
);

export const AWARE_CLICK_CLOSE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/camera-aware",
  "fechar"
);

export const TRY_AGAIN_CLICK_CONTINUE_AWARE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/aware-tente-novamente-reconhecimento",
  "continuar"
);

export const TRY_AGAIN_CLICK_HELP_AWARE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/aware-tente-novamente-reconhecimento",
  "duvidas-frequentes"
);

export const TRY_AGAIN_CLICK_BACK_AWARE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/aware-tente-novamente-reconhecimento",
  "voltar"
);

export const FACETEC_CAPTURE_SUCCESS = createAnalyticsEvent(
  "success",
  "/biometria/usar/autenticacao/camera-facetec",
  "sucesso-captura-foto"
);

export const FACETEC_REGISTER_SUCCESS = createAnalyticsEvent(
  "conversion",
  "/biometria/usar/autenticacao/camera-facetec",
  "sucesso-registro-foto"
);

export const FACETEC_CLICK_INIT = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/camera-facetec",
  "comecar"
);

export const FACETEC_CLICK_CLOSE = createAnalyticsEvent(
  "click",
  "/biometria/usar/autenticacao/camera-facetec",
  "fechar"
);