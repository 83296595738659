export var Config = (function () {
  // -------------------------------------
  // REQUIRED
  // Available at https://dev.facetec.com/account
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var DeviceKeyIdentifier = "dvGH6mawVRC7xlVk6Fxl4LwACtYo4ush";

  // -------------------------------------
  // REQUIRED
  // The URL to call to process FaceTec SDK Sessions.
  // In Production, you likely will handle network requests elsewhere and without the use of this variable.
  // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var BaseURL = "https://prod-core-api.bioservices.certisign.com.br/api/v1";

  // var CredentialUser = "60701190000104";
  // -------------------------------------
  // REQUIRED
  // The FaceMap Encryption Key you define for your application.
  // Please see https://dev.facetec.com/keys?link=keys for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var PublicFaceScanEncryptionKey =
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" +
    "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" +
    "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" +
    "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" +
    "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" +
    "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" +
    "8QIDAQAB\n" +
    "-----END PUBLIC KEY-----";

  // -------------------------------------
  // Convenience method to initialize the FaceTec SDK.
  // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
  function initializeFromAutogeneratedConfig(FaceTecSDK, key, callback, publicKey, deviceKey) {
    FaceTecSDK.initializeInProductionMode(key, deviceKey || this.DeviceKeyIdentifier, publicKey || this.PublicFaceScanEncryptionKey,
      function (initializedSuccessfully) {
        callback(initializedSuccessfully);
      });
  }

  ;

  // This app can modify the customization to demonstrate different look/feel preferences
  // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
  function retrieveConfigurationWizardCustomization(FaceTecSDK) {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    defaultCustomization = getCustomization(defaultCustomization)

    this.currentCustomization = defaultCustomization;

    return defaultCustomization;
  }

  ;

  function retrieveLowLightConfigurationWizardCustomization(FaceTecSDK) {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    defaultCustomization = getCustomization(defaultCustomization)

    this.currentLowLightCustomization = defaultCustomization;

    return defaultCustomization;
  }

  ;

  function retrieveDynamicDimmingConfigurationWizardCustomization(FaceTecSDK) {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    this.currentDynamicDimmingCustomization = defaultCustomization;
    return defaultCustomization;
  }

  ;

  var currentCustomization;
  var currentLowLightCustomization;
  var currentDynamicDimmingCustomization;

  // -------------------------------------
  // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
  // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's "Toggle Colors & Themes" menu,
  // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the

  // Configuration Wizard.
  var wasSDKConfiguredWithConfigWizard = false;

  function getCustomization(defaultCustomization) {
    // Personalizado
    var itauColor = "#EC7000";
    var textColor = "#231D19";
    var itauDarkColor = "#f24f00";
    var white = "#ffffff";
    var disabledColor = "#CCCCCC";


    // For Frame Corner Radius Customization
    let frameCornerRadius = "20px";

    // For Cancel Button Customization
    var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopRight;

    // For image Customization
    var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec_ZoOm;

    // Set a default customization
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();

    // Set Frame Customization
    defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius;
    defaultCustomization.frameCustomization.backgroundColor = white;
    defaultCustomization.frameCustomization.borderColor = white;

    // Set Overlay Customization
    // Remover Your Logo
    defaultCustomization.overlayCustomization.showBrandingImage = false;
    defaultCustomization.overlayCustomization.brandingImage = ""; // yourAppLogoImage;
    // defaultCustomization.overlayCustomization.backgroundColor = white;

    // Set Guidance Customization
    defaultCustomization.guidanceCustomization.backgroundColors = white;
    defaultCustomization.guidanceCustomization.foregroundColor = textColor;
    defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = itauColor;
    defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = disabledColor;
    defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = itauColor;
    defaultCustomization.guidanceCustomization.buttonTextNormalColor = white;
    defaultCustomization.guidanceCustomization.buttonTextDisabledColor = white;
    defaultCustomization.guidanceCustomization.buttonTextHighlightColor = white;
    defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = white;

    // Ajustar cor da Elipse para laranja
    defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = itauColor;

    // Set Oval Customization
    defaultCustomization.ovalCustomization.strokeColor = itauColor;
    defaultCustomization.ovalCustomization.progressColor1 = itauColor;
    defaultCustomization.ovalCustomization.progressColor2 = itauColor;

    // Set Feedback Customization
    defaultCustomization.feedbackCustomization.backgroundColor = white;
    defaultCustomization.feedbackCustomization.textColor = textColor;
    // Set Cancel Customization
    // defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage;
    defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation;

    // Set Security Watermark Customization
    defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage);

    // Set Result Screen Customization
    defaultCustomization.resultScreenCustomization.backgroundColors = white;
    defaultCustomization.resultScreenCustomization.foregroundColor = textColor;
    defaultCustomization.resultScreenCustomization.activityIndicatorColor = itauDarkColor;
    defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = itauDarkColor;
    defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = white;
    defaultCustomization.resultScreenCustomization.uploadProgressFillColor = itauDarkColor;

    // Set ID Scan Customization
    defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = white;
    defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;
    defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = white;
    defaultCustomization.idScanCustomization.reviewScreenForegroundColor = white;
    defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = itauDarkColor;
    defaultCustomization.idScanCustomization.captureScreenForegroundColor = white;
    defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = itauDarkColor;
    defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = itauDarkColor;
    defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = itauColor;
    defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = itauColor;
    defaultCustomization.idScanCustomization.buttonTextNormalColor = white;
    defaultCustomization.idScanCustomization.buttonTextDisabledColor = white;
    defaultCustomization.idScanCustomization.buttonTextHighlightColor = white;
    defaultCustomization.idScanCustomization.captureScreenBackgroundColor = white;
    defaultCustomization.idScanCustomization.captureFrameStrokeColor = white;

    // Set Initial Loading Customization
    defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = white;
    defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = itauDarkColor;
    defaultCustomization.initialLoadingAnimationCustomization.messageTextColor = textColor;

    // alterar cor loader
    // defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = "rgba(255,255,255,0.0)";
    // defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = "rgba(255,255,255,0.0)";


    defaultCustomization.vocalGuidanceCustomization.mode = 2;

    return defaultCustomization;
  }

  return {
    wasSDKConfiguredWithConfigWizard,
    DeviceKeyIdentifier,
    BaseURL,
    PublicFaceScanEncryptionKey,
    initializeFromAutogeneratedConfig,
    currentCustomization,
    currentLowLightCustomization,
    currentDynamicDimmingCustomization,
    retrieveConfigurationWizardCustomization,
    retrieveLowLightConfigurationWizardCustomization,
    retrieveDynamicDimmingConfigurationWizardCustomization
  };
})();