import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsPage } from '../../interfaces/ErrorsPage';
import { verifyUrlOfAgent } from '../../../app/shared/helpers/verification';
import { ERROR_BLOCKED, ERROR_CAM_NOT_RESPOND_PAGE, ERROR_CAM_PERMISSION_PAGE, ERROR_GENERIC, ERROR_LIMIT_EXCEED, ERROR_CAM_PERMISSION_CLICK_BACK, ERROR_CAM_PERMISSION_CLICK_CONFIG, ERROR_CAM_NOT_RESPOND_CLICK_BACK, ERROR_CAM_NOT_RESPOND_CLICK_OK, ERROR_LIMIT_EXCEED_CLICK_BACK, ERROR_BLOCKED_CLICK_CLOSE, ERROR_GENERIC_CLICK_CLOSE, ERROR_GENERIC_CLICK_UNDERSTOOD } from '../../shared/helpers/paramsGa4';
import { MainService } from '../../services/main.service';
import { LOGS_ERROR_PAGE_BLOCK, LOGS_ERROR_PAGE_GENERIC, LOGS_ERROR_PAGE_NO_RESPOSE, LOGS_ERROR_PAGE_PERMISSION, LOGS_ERROR_PAGE_TRIES, LOGS_ERROR_PAGE_INCOMPATIBLE_BROWSER } from '../../shared/helpers/stepsToLogs';
import { dataStorageClean } from '../../shared/helpers/dataStorage';
import { LocalStorage, SessionStorage } from '../../interfaces/dataStorage';
import { AnalyticsGA4Service } from '../../services/analyticsGA4.service';
import { datadogSetErrorType } from '../../shared/helpers/datadog';
import { verifyDeviceInfoOfAgent } from '../../shared/helpers/verification';

const ERROR_URL = 'www.itau.com.br/';
const DEFAULT_ERROR = {
  title: 'Desculpe, algo deu errado',
  icon: 'aviso_outline',
  description: 'Não foi possível carregar as informações neste momento. Por favor, tente outra vez',
  button: 'Fechar',
  track: ERROR_GENERIC,
  log: LOGS_ERROR_PAGE_GENERIC
};

@Component({
  selector: 'error-root',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  title: string;
  description: string;
  button: string;
  icon: string;
  @ViewChild('elementFocus') elementFocus: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private analyticsGA4Service: AnalyticsGA4Service,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.handleRoute();
    this.setRedirectUrlError();
  }

  ngAfterViewInit() {
    this.elementFocus.nativeElement.focus();
  }

  handleRoute() {
    const toggle = localStorage.getItem(LocalStorage.ToggleLogs) === 'true';
    const errorCode = this.verifyNavigationEntry();
    const errorConfig = this.getErrorConfig(errorCode);

    this.title = errorConfig.title;
    this.icon = errorConfig.icon;
    this.description = errorConfig.description;
    this.button = errorConfig.button;
    this.analyticsGA4Service.trackEventGA4(errorConfig.track);
    datadogSetErrorType(errorCode);

    if (toggle) {
      this.mainService.insightsAPI(errorConfig.log);
    }
  }

  getErrorCode() {
    const errorCode = this.route.snapshot?.queryParams?.errorCode;
    return errorCode ?? ErrorsPage.ERROR_SERVER;
  }

  getErrorConfig(errorCode: string) {
    switch (errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
        return {
          title: 'Autorize o acesso à câmera para confirmar sua identidade',
          icon: 'aviso_outline',
          description: 'Por favor, autorize o acesso à câmera para continuar com o reconhecimento facial',
          button: 'Abrir configurações',
          track: ERROR_CAM_PERMISSION_PAGE,
          log: LOGS_ERROR_PAGE_PERMISSION
        };
      case ErrorsPage.CAMERA_ERROR:
        return {
          title: 'A câmera não respondeu, por isso não conseguimos fazer seu reconhecimento facial',
          icon: 'maquina_fotografica_outline',
          description: 'Sem sua câmera, não é possível tirar sua foto para o reconhecimento facial',
          button: 'Fechar',
          track: ERROR_CAM_NOT_RESPOND_PAGE,
          log: LOGS_ERROR_PAGE_NO_RESPOSE
        };
      case ErrorsPage.LIMIT_ERROR:
        return {
          title: 'Você atingiu o limite de tentativas de captura',
          icon: 'aviso_outline',
          description: 'Por segurança, não conseguimos seguir com a solicitação sem confirmar sua identidade. Tente novamente mais tarde',
          button: 'Fechar',
          track: ERROR_LIMIT_EXCEED,
          log: LOGS_ERROR_PAGE_TRIES
        };
      case ErrorsPage.LIMIT_BLOCK:
        return {
          title: 'Você atingiu o limite de tentativas de captura',
          icon: 'aviso_outline',
          description: 'Por segurança, não conseguimos seguir com a solicitação sem confirmar sua identidade. Tente novamente amanhã',
          button: 'Fechar',
          track: ERROR_BLOCKED,
          log: LOGS_ERROR_PAGE_BLOCK
        };
      case ErrorsPage.FAIL_KEY:
      case ErrorsPage.BACK_BUTTON_BROWSER:
      case ErrorsPage.RELOADED_PAGE:
        return {
          title: 'O tempo para tirar sua foto acabou',
          icon: 'aviso_outline',
          description: 'Por segurança, não conseguimos seguir com a solicitação. Tente novamente mais tarde',
          button: 'Fechar',
          track: ERROR_GENERIC,
          log: LOGS_ERROR_PAGE_GENERIC
        };
      case ErrorsPage.INCOMPATIBLE_BROWSER:
        return {
          title: 'Não foi possível acessar a câmera com o seu navegador',
          icon: 'aviso_outline',
          description: 'Para fazer o reconhecimento facial, baixe um novo navegador ou inicie uma sessão em outro device',
          button: 'Entendi',
          track: ERROR_CAM_PERMISSION_PAGE,
          log: LOGS_ERROR_PAGE_INCOMPATIBLE_BROWSER
        };
      case ErrorsPage.ERROR_SERVER:
        return DEFAULT_ERROR;
      default:
        return DEFAULT_ERROR;
    }
  }

  handleButton() {
    const errorCode = this.getErrorCode();
    let eventToTrack: any;

    switch (errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
        window.open(verifyUrlOfAgent(), '_blank');
        eventToTrack = ERROR_CAM_PERMISSION_CLICK_CONFIG;
        break;
      case ErrorsPage.CAMERA_ERROR:
        eventToTrack = ERROR_CAM_NOT_RESPOND_CLICK_OK;
        this.callRedirect();
        break;
      case ErrorsPage.LIMIT_ERROR:
        eventToTrack = ERROR_LIMIT_EXCEED_CLICK_BACK;
        this.callRedirect();
        break;
      case ErrorsPage.LIMIT_BLOCK:
        eventToTrack = ERROR_BLOCKED_CLICK_CLOSE;
        this.callRedirect();
        break;
      case ErrorsPage.FAIL_KEY:
      case ErrorsPage.ERROR_SERVER:
        eventToTrack = ERROR_GENERIC_CLICK_UNDERSTOOD;
        this.callRedirect();
        break;
      default:
        eventToTrack = ERROR_GENERIC_CLICK_UNDERSTOOD;
        this.callRedirect();
        break;
    }

    if (eventToTrack) {
      this.analyticsGA4Service.trackEventGA4(eventToTrack);
    }
  }

  callRedirect() {
    const params = sessionStorage.getItem(SessionStorage.ParamsUrl);
    const type = this.getRedirectType(this.getErrorCode());
    window.document.location.href = `https://${sessionStorage.getItem(SessionStorage.RedirectUrlError)}/?&type=${type}${params}`;
    dataStorageClean();
  }

  getRedirectType(errorCode: string): string {
    switch (errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
      case ErrorsPage.CAMERA_ERROR:
        return 'error_no_permission';
      case ErrorsPage.LIMIT_ERROR:
      case ErrorsPage.LIMIT_BLOCK:
        return 'error_no_frame';
      case ErrorsPage.FAIL_KEY:
      case ErrorsPage.ERROR_SERVER:
        return 'no_session';
      default:
        return 'unknown_error';
    }
  }

  setRedirectUrlError() {
    const url = sessionStorage.getItem(SessionStorage.RedirectUrlError);
    if (!url) {
      sessionStorage.setItem(SessionStorage.RedirectUrlError, ERROR_URL);
    }
  }

  buttonClose() {
    this.callRedirect();
    const errorCode = this.getErrorCode();
    let eventToTrack: any;

    switch (errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
        eventToTrack = ERROR_CAM_PERMISSION_CLICK_BACK;
        break;
      case ErrorsPage.CAMERA_ERROR:
        eventToTrack = ERROR_CAM_NOT_RESPOND_CLICK_BACK;
        break;
      case ErrorsPage.LIMIT_ERROR:
        eventToTrack = ERROR_LIMIT_EXCEED_CLICK_BACK;
        break;
      case ErrorsPage.LIMIT_BLOCK:
        eventToTrack = ERROR_BLOCKED_CLICK_CLOSE;
        break;
      case ErrorsPage.FAIL_KEY:
      case ErrorsPage.ERROR_SERVER:
      default:
        eventToTrack = ERROR_GENERIC_CLICK_CLOSE;
        break;
    }

    this.analyticsGA4Service.trackEventGA4(eventToTrack);
  }

  verifyNavigationEntry() {
    let navigationEntryType: string | undefined;

    if (typeof window.performance.getEntries === 'function') {
      const entries = window.performance.getEntries();
      if (entries.length > 0) {
        navigationEntryType = (entries[0] as PerformanceNavigationTiming).type;
      }
    }

    switch (navigationEntryType) {
      case "back_forward":
        datadogSetErrorType(ErrorsPage.BACK_BUTTON_BROWSER);
        return ErrorsPage.BACK_BUTTON_BROWSER;
      case "reload":
        datadogSetErrorType(ErrorsPage.RELOADED_PAGE);
        return ErrorsPage.RELOADED_PAGE;
      default:
        return this.route.snapshot?.queryParams?.errorCode;
    }
  }

  verifyIsSafari() {
    const { browser } = verifyDeviceInfoOfAgent();
    return browser === 'Safari';
  }
}