<ids-mobile-header [scrollElement]="true">
  <button idsMobileHeaderAction idsIconButton variant="neutral" title="voltar" aria-label="voltar"
    (click)="buttonBack()"> seta_esquerda</button>
  <button idsMobileHeaderControls idsIconButton variant="neutral" title="ajudar" aria-label="ajudar"
    (click)="callHelpPage()"> ajuda_outline</button>
</ids-mobile-header>

<div class="main-content" [class.isSafari]="verifyIsSafari()">
  <h1 class="ids-title -small" tabindex="-1" id="main-title" #elementFocus>Tente novamente seguindo essas dicas para
    tirar sua foto
  </h1>

  <div class="img" aria-hidden="true">
    <img alt="gif lampada" aria-hidden="true" src="assets/images/lamp.svg" />
  </div>

  <div class="list-tips">
    <ol idsList>
      <li idsLi>
        <ids-icon idsLiIcon>usuario_outline</ids-icon>
        <span idsLiText class="list-item">Prefira fazer sua foto em um ambiente tranquilo e seguro</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>mode_light_base</ids-icon>
        <span idsLiText class="list-item">Garanta uma boa iluminação</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>visivel_outline</ids-icon>
        <span idsLiText class="list-item">Mantenha o celular na altura do rosto</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>sorriso_outline</ids-icon>
        <span idsLiText class="list-item">Retire óculos, boné ou outros acessórios</span>
      </li>
    </ol>
  </div>

  <div class="main-footer">
    <button id="main-button" idsMainButton (click)="startCam()" title="continuar" aria-label="continuar" full>
      Continuar
      <ids-icon>seta_direita</ids-icon>
    </button>
  </div>
</div>

<bottom-sheet [pageRoute]="this.routePage"></bottom-sheet>