<ids-bottom-sheet [(open)]="isOpenBottomSheet" aria-labelledby="with-primary-button-title-id">
  <h2 idsBottomSheetPresentation id="with-primary-button-title-id">
    Atenção
  </h2>
  <span class="ids-body-text -xsmall">Tem certeza que deseja sair do reconhecimento facial?</span>
  <footer class="ids-mt-4">
    <button idsMainButton full="true" (click)="callToGoOut()">
      Sair
    </button>
    <button idsMainButton full="true" variant="secondary" class="ids-mt-2" (click)="callBottomSheet()">
      Continuar reconhecimento facial
    </button>
  </footer>
</ids-bottom-sheet>