<ids-mobile-header [scrollElement]="true">
  <button idsMobileHeaderControls idsIconButton variant="neutral" title="ajudar" aria-label="ajudar"
    (click)="buttonClose()"> fechar</button>
</ids-mobile-header>

<div class="main-content" [class.isSafari]="verifyIsSafari()">
  <div class="ids-mt-4">
    <ids-icon class="icon-page" size="L" variant="error">{{icon}}</ids-icon>
    <h1 class="ids-title -small ids-mt-1 ids-mb-1" tabindex="-1" id="main-title" #elementFocus>{{title}}</h1>
    <span class="ids-overline-text --bold">{{description}}</span>
  </div>

  <div class="main-footer">
    <button idsMainButton (click)="handleButton()" full>
      {{button}}
    </button>
  </div>
</div>