import { SessionStorage } from '../../interfaces/dataStorage';
import { dataStorageClean } from './dataStorage';
import { RoutesPages } from '../../interfaces/routes';

const redirectTo = (urlKey: string, redirectKey: string) => {
  const url = sessionStorage.getItem(urlKey);
  const redirect = sessionStorage.getItem(redirectKey);
  window.document.location.href = url ? redirect || RoutesPages.UrlCallBackDefault : RoutesPages.UrlCallBackDefault;
  dataStorageClean();
};

export const callSuccess = () => {
  redirectTo(SessionStorage.RedirectUrl, SessionStorage.RedirectSuccess);
};

export const callError = () => {
  redirectTo(SessionStorage.RedirectUrlError, SessionStorage.RedirectError);
};

export const callRetry = () => {
  const redirectMappings = [
    { urlKey: SessionStorage.RedirectUrlRetry, redirectKey: SessionStorage.RedirectRetry },
    { urlKey: SessionStorage.RedirectUrlError, redirectKey: SessionStorage.RedirectError }
  ];

  const redirectMapping = redirectMappings.find(mapping => sessionStorage.getItem(mapping.urlKey));

  if (redirectMapping) {
    redirectTo(redirectMapping.urlKey, redirectMapping.redirectKey);
  } else {
    window.document.location.href = RoutesPages.UrlCallBackDefault;
  }

  dataStorageClean();
};