import { Component, ElementRef, ViewChild } from '@angular/core';
import *as labelsHelp from '../../../assets/locales/pt-br';
import { ActivatedRoute, Router } from '@angular/router';
import { HELP_PAGE, HELP_PAGE_CLICK, HELP_PAGE_CLICK_BACK, HELP_PAGE_CLICK_POLICY } from '../../shared/helpers/paramsGa4';
import { MainService } from '../../services/main.service';
import { LOGS_HELP_PAGE } from '../../shared/helpers/stepsToLogs';
import { LocalStorage } from '../../interfaces/dataStorage';
import { RoutesPages } from '../../interfaces/routes';
import { AnalyticsGA4Service } from '../../services/analyticsGA4.service';

@Component({
  selector: 'help-root',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  labels: any;
  @ViewChild('elementFocus') elementFocus: ElementRef;

  constructor(
    private router: Router,
    private analyticsGA4Service: AnalyticsGA4Service,
    public mainService: MainService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.labels = labelsHelp.helpPage;
    this.analyticsGA4Service.trackEventGA4(HELP_PAGE);
    if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
      this.mainService.insightsAPI(LOGS_HELP_PAGE);
    }
  }

  ngAfterViewInit() {
    this.elementFocus.nativeElement.focus();
  }

  goBack() {
    const navigationReturn: string = this.route.snapshot?.queryParams?.page || RoutesPages.Error;
    this.router.navigate([navigationReturn], { queryParams: { type: true } });
    this.analyticsGA4Service.trackEventGA4(HELP_PAGE_CLICK_BACK);
  }

  handleEvent(question: string) {
    if (!question) return;

    const formatted = question
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')
      .substring(0, 100);

    this.analyticsGA4Service.trackEventGA4(HELP_PAGE_CLICK(formatted));
  }

  openPolicy() {
    window.open("https://www.itau.com.br/seguranca/termos-de-uso", '_blank');
    this.analyticsGA4Service.trackEventGA4(HELP_PAGE_CLICK_POLICY);
  }
}