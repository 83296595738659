

export enum ErrorsPage {
  CAMERA_CONFIG = 'camera_config',
  CAMERA_ERROR = 'camera_error',
  LIMIT_ERROR = 'limit_error',
  LIMIT_BLOCK = 'limit_block',
  FAIL_KEY = 'fail_key',
  ERROR_SERVER = 'error_server',
  INCOMPATIBLE_BROWSER = 'incompatible_browser',
  BACK_BUTTON_BROWSER = 'back_button_browser_error',
  RELOADED_PAGE = 'reloaded_page_error',
  NO_SUPPLIER = 'no_supplier'
}