import { Component } from '@angular/core';
import { AnalyticsGA4Service } from './services/analyticsGA4.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private analyticsGA4Service: AnalyticsGA4Service
  ) { }

  async ngOnInit() {
    await this.analyticsGA4Service.initGA4Sdk();
  }
}