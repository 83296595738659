import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesPages } from '../interfaces/routes';
import { ErrorsPage } from '../interfaces/ErrorsPage';
import { datadogSetErrorType } from '../shared/helpers/datadog';

@Injectable({
  providedIn: 'root',
})
export class NavigationEntryService {
  constructor(
    public router: Router,
  ) { }

  verifyNavigationEntry(): void {
    let navigationEntryType: string | undefined;

    if (typeof window.performance.getEntries === 'function') {
      const entries = window.performance.getEntries();
      if (entries.length > 0) {
        navigationEntryType = (entries[0] as PerformanceNavigationTiming).type;
      }
    }

    switch (navigationEntryType) {
      case "back_forward":
        this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.BACK_BUTTON_BROWSER } });
        datadogSetErrorType(ErrorsPage.BACK_BUTTON_BROWSER);
        break;
      case "reload":
        this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.RELOADED_PAGE } });
        datadogSetErrorType(ErrorsPage.RELOADED_PAGE);
        break;
    }
  }
}