<ids-mobile-header>
	<button idsMobileHeaderControls idsIconButton title="fechar" aria-label="fechar" class="ids-color-text-highlight"
		(click)="callButtomSheet()">
		fechar</button>
</ids-mobile-header>

<div id="previewSection">
	<div>
		<div id="feedbackSection">
			<input id="feedbackText" readonly="readonly" />
		</div>
		<div class="progress">
			<div id="speech-progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
		</div>
	</div>
	<div>
		<video id="previewWindow" class="mirrorVideo" playsinline autoplay muted>
			Your browser does not support the video tag.
		</video>
		<canvas id="ovalOverlay"></canvas>
		<img id="frontImage" alt src="" aria-response="{}" />
	</div>
</div>

<div class="main-footer">
	<button id="captureBtn" idsMainButton (click)="clickTakePicture()" type="button" class="ids-color-bg-brand-primary"
		full>
		<span #elementFocus>tirar minha foto</span></button>
</div>

<bottom-sheet [pageRoute]="this.routePage"></bottom-sheet>

<loading *ngIf="initPage" [showFooter]="false"></loading>

<loading *ngIf="this.finishPageOne" icon="maquina_fotografica_outline" label="Analisando sua foto" [showFooter]="true">
</loading>

<loading *ngIf="this.finishPageTwo" icon="maquina_fotografica_outline"
	label="Medindo pontos do seu rosto com nossa tecnologia" [showFooter]="true"></loading>