export const LOGS_TIPS_PAGE = [
  {
    action: "inicializou a aplicação",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "Iniciou tela de dicas",
    timestamp: new Date().getTime()
  }
];

export const LOGS_START_FACETEC = [
  {
    action: "inicializou a facetec",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "Startou a Camera",
    timestamp: new Date().getTime()
  }
];

export const LOGS_START_AWARE = [
  {
    action: "inicializou a aware",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "Startou a Camera",
    timestamp: new Date().getTime()
  }
];

export const LOGS_ERROR_PAGE_PERMISSION = [
  {
    action: "Inicializou a aplicação sem permissão de câmera",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "Autorize acesso camera reconhecimento",
    timestamp: new Date().getTime()
  }
];

export const LOGS_ERROR_PAGE_NO_RESPOSE = [
  {
    action: "Camera sem resposta",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "Camera sem resposta",
    timestamp: new Date().getTime()
  }
];

export const LOGS_ERROR_PAGE_TRIES = [
  {
    action: "Tela erro quarentena",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "User excedeu as tentativas",
    timestamp: new Date().getTime()
  }
];

export const LOGS_ERROR_PAGE_BLOCK = [
  {
    action: "Tela erro bloqueio",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "User bloqueado",
    timestamp: new Date().getTime()
  }
];

export const LOGS_ERROR_PAGE_GENERIC = [
  {
    action: "Tela erro generico",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "Erro generico",
    timestamp: new Date().getTime()
  }
];

export const LOGS_ERROR_PAGE_INCOMPATIBLE_BROWSER = [
  {
    action: "Tela erro browser incompatível",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "Erro de browser incompatível, não foi possível verificar a camera",
    timestamp: new Date().getTime()
  }
];

export const LOGS_HELP_PAGE = [
  {
    action: "Tela de ajuda",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "User abriu tela de ajuda",
    timestamp: new Date().getTime()
  }
];

export const LOGS_NEW_TRY_PAGE = [
  {
    action: "Tela de Nova tentativa",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "User caiu no fluxo de tentar novamente",
    timestamp: new Date().getTime()
  }
];

export const LOGS_SUCCESS_PAGE = [
  {
    action: "Tela de Sucesso",
    exception: {
      message: "not sent"
    },
    request: "not sent",
    response: "not sent",
    step: "User caiu no fluxo de sucesso",
    timestamp: new Date().getTime()
  }
];