<div class="header-shimmer" *ngIf="showShimmer" aria-label="shimmer">
  <ids-shimmer></ids-shimmer>
  <ids-shimmer></ids-shimmer>
</div>

<ids-mobile-header [scrollElement]="true" *ngIf="!showShimmer" [headingElement]="bgBaseH1">
  <button idsMobileHeaderAction idsIconButton variant="neutral" title="voltar" aria-label="voltar"
    (click)="callButtomSheet()" *ngIf="showButtonBack">seta_esquerda</button>
  <button idsMobileHeaderControls idsIconButton variant="neutral" title="ajudar" aria-label="ajudar"
    (click)="callHelpPage()">ajuda_outline</button>
</ids-mobile-header>

<div class="main-content" [class.isSafari]="verifyIsSafari()">
  <div class="title">
    <ids-shimmer height="24px" width="50%" [show]="showShimmer">
      <h1 class="ids-overline-text" #bgBaseH1 #elementFocus tabindex="-1">Reconhecimento facial</h1>
    </ids-shimmer>
    <ids-shimmer [show]="showShimmer">
      <h2 class="ids-title -small" id="main-title">Para continuar vamos confirmar sua
        identidade</h2>
    </ids-shimmer>
  </div>

  <div class="img" aria-hidden="true">
    <ids-shimmer [show]="showShimmer" width="200px">
      <lottie-player src="../../../assets/images/ids_illustration_woman_biometra_spothero.json" background="transparent"
        speed="1" loop autoplay>
      </lottie-player>
    </ids-shimmer>
  </div>

  <div class="list-tips">
    <ol idsList>
      <ids-shimmer [show]="showShimmer">
        <li idsLi>
          <ids-icon idsLiIcon>usuario_outline</ids-icon>
          <span idsLiText class="list-item">Apenas o titular do CPF cadastrado deve tirar a foto para essa
            confirmação</span>
        </li>
      </ids-shimmer>
      <ids-shimmer [show]="showShimmer">
        <li idsLi lineOff>
          <ids-icon idsLiIcon>fraud_check_base</ids-icon>
          <span idsLiText class="list-item">As imagens são usadas para validação e não para foto de perfil</span>
        </li>
      </ids-shimmer>
    </ol>
  </div>

  <div class="main-footer">
    <ids-shimmer [show]="showShimmer">
      <button idsMainButton class="ids-main-button" (click)="startCam()" title="continuar" aria-label="continuar" full
        [loading]="showButton">
        Continuar
        <ids-icon>seta_direita</ids-icon>
      </button>
    </ids-shimmer>
  </div>
</div>

<loading *ngIf="this.showLoading" [showFooter]="false"></loading>

<bottom-sheet [pageRoute]="this.routePage"></bottom-sheet>