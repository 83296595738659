import { Injectable } from "@angular/core";
import { QualityPage, ResponseLiveness, ResponseRegister, StatusFromLiveness, SupplierLiveness } from '../interfaces/ResponsesServices';
import { Router } from '@angular/router';
import { ErrorsPage } from '../interfaces/ErrorsPage';
import { Supplier } from '../interfaces/Suppliers';
import { environment } from '../../environments/environment';
import { AWARE_CAPTURE_SUCCESS, FACETEC_REGISTER_SUCCESS, FACETEC_CAPTURE_SUCCESS } from '../shared/helpers/paramsGa4';
import { FacetecService } from "../services/facetec.service";
import { LocalStorage, SessionStorage } from "../interfaces/dataStorage";
import { datadogSetErrorType, datadogSetNumberTry } from "../shared/helpers/datadog";
import { RoutesPages } from '../interfaces/routes';
import { AnalyticsGA4Service } from "../services/analyticsGA4.service";

@Injectable({
	providedIn: 'root'
})
export class EndFactoryService {

	countNewTry: number = 0;

	constructor(
		public router: Router,
		private analyticsGA4Service: AnalyticsGA4Service,
		public facetecService: FacetecService,
	) {
		// @ts-ignore
		window.endFlowFacetec = this.endFlowFacetec.bind(this);
	}

	// Metodo usado no endFlow do fluxo antigo, será removido na Bio 2.0
	async resolveFactory(response: ResponseRegister, type?: string): Promise<void> {
		const supplier = sessionStorage.getItem(LocalStorage.Supplier);
		switch (supplier) {
			case Supplier.FACETEC:
				this.analyticsGA4Service.trackEventGA4(FACETEC_REGISTER_SUCCESS);
				break;
			case Supplier.AWARE:
				this.endFlowAware(response);
				this.analyticsGA4Service.trackEventGA4(AWARE_CAPTURE_SUCCESS);
				break;
		}
	}

	// Fluxo Bio 2.0
	// async resolveFactory(response: ResponseLiveness): Promise<void> {
	// 	const supplier = sessionStorage.getItem(LocalStorage.Supplier);
	// 	const newSupplier = response.data.fornecedor.nome;
	// 	switch (response.data.resultado) {
	// 		case StatusFromLiveness.SUCCESS:
	// 			// chama tela de sucesso e finaliza*
	// 			this.router.navigate([RoutesPages.Success], { queryParams: { code: QualityPage.SUCCESS }, skipLocationChange: true });
	// 			break;
	// 		case StatusFromLiveness.BLOCKED:
	// 			this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.LIMIT_BLOCK }, skipLocationChange: true });
	// 			break;
	// 		case StatusFromLiveness.REJECTED:
	// 			// QUANDO for possivel unificar as telas de new-try utilizar a logica abaixo:

	// 			// Se o supplier atual for diferente do respondido pela API
	// 			// será preciso chamar as configuraçoes de inicialização de fornecedor
	// 			// if (supplier?.toLowerCase() !== newSupplier.toLowerCase()) {
	// 			// 	// Lembrando que os labels podem mudar pois hj o back está apenas mocado
	// 			// 	const supplier = response?.data?.fornecedor?.nome;
	// 			// 	const token = response?.data?.fornecedor?.parametros?.token;
	// 			// 	const keyLicense = response?.data?.fornecedor?.parametros?.licenca;
	// 			// 	const dateExpiryKey = response?.data?.fornecedor?.parametros?.dateExpiryKey;
	// 			// 	const tokenLiveness = response?.data?.fornecedor?.parametros?.tokenLiveness;
	// 			// 	const publicKey = response?.data?.fornecedor?.parametros?.chave;
	// 			// 	const deviceKey = response?.data?.fornecedor?.parametros?.chave_dispositivo;
	// 			// 	this.reInitServices(supplier, keyLicense, dateExpiryKey, tokenLiveness, publicKey, deviceKey);
	// 			// 	saveTokens(supplier, token);
	// 			// }

	// 			// Hoje nesse fluxo só temos AWARE então apenas precisamos validar e chamar o redirecionamento
	// 			this.router.navigate([RoutesPages.NewTry]);
	// 			break;
	// 	}
	// }

	// Metodo usado no endFlow do fluxo antigo, será removido na Bio 2.0
	endFlowAware(response: ResponseRegister): void {
		const isCaptured = response.capturado;
		const isNewTry = response.nova_tentativa;

		if (isCaptured) {
			// chama tela de sucesso e finaliza*
			this.router.navigate([RoutesPages.Success], { queryParams: { code: QualityPage.SUCCESS } });
			this.analyticsGA4Service.trackEventGA4(AWARE_CAPTURE_SUCCESS);
		} else {
			// Quando não foi capturado é preciso saber se é uma nova tentativa ou se o usuário está bloqueado.
			if (isNewTry) {
				if (this.countNewTry < environment.tryQuantity) {
					this.countNewTry++;
					this.router.navigate([RoutesPages.NewTry]);
				} else {
					this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.LIMIT_ERROR } });
					datadogSetErrorType(ErrorsPage.LIMIT_ERROR);
				}
			} else {
				this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.LIMIT_BLOCK } });
				datadogSetErrorType(ErrorsPage.LIMIT_BLOCK);
			}
		}
	}

	// Metodo usado no endFlow do fluxo antigo, será removido na Bio 2.0
	endFlowFacetec(result, faceScanResultCallback, scanResultBlob): void {
		const count = localStorage.getItem(LocalStorage.NumberTry) || "1";
		// Se o retorno é todo falso, o user está bloqueado
		if (!result.capturado && !result.nova_tentativa && !result.aprovado_match) {
			sessionStorage.setItem(SessionStorage.SetStatusOfReturn, 'error_block')
			faceScanResultCallback.cancel();
		} else {
			// Se o temos uma captura true ou nova tentativa como true e o user ainda não excedeu as tentativas, podemos seguir
			if ((result.capturado || result.nova_tentativa) && parseInt(count) < environment.tryQuantity) {
				faceScanResultCallback.proceedToNextStep(scanResultBlob);
				if (result.capturado) {
					this.analyticsGA4Service.trackEventGA4(FACETEC_CAPTURE_SUCCESS);
				}
			} else {
				// Se não temos temos que exibir a tela de limite excedido
				sessionStorage.setItem(SessionStorage.SetStatusOfReturn, 'error')
				faceScanResultCallback.cancel();
			}
		}
	}

	async reInitServices(supplier: string, keyLicense: string, dateExpiryKey: string, tokenLiveness: string, publicKey?: string, deviceKey?: string) {
		if (supplier === Supplier.FACETEC) {
			localStorage.setItem(LocalStorage.NumberTry, '0');
			await this.facetecService.initFacetec(
				keyLicense,
				dateExpiryKey,
				tokenLiveness,
				publicKey,
				deviceKey
			);
			datadogSetNumberTry();
		}
	}

}
